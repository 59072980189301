/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'dashboard',
          component: () => import('./views/Dashboard.vue')
        },
        {
          path: '/gestione-abbonamenti/cerca-utente',
          name: 'cerca-utente',
          component: () => import('./views/cerca-utente.vue')
        },
        {
          path: '/gestione-abbonamenti/cgr',
          name: 'cgr',
          component: () => import('./views/cgr.vue')
        },
        {
          path: '/gestione-abbonamenti/lista-abbonati/',
          name: 'litsa-abbonati',
          component: () => import('./views/abbonamenti.vue')
        },
        {
          path: '/gestione-abbonamenti/liste-omaggio',
          name: 'liste-omaggio',
          component: () => import('./views/listeOmaggio.vue')
        },
        {
          path: '/gestione-abbonamenti/rispedizioni-riviste',
          name: 'rispedizioni-riviste',
          component: () => import('./views/rispedizioni.vue')
        },
        {
          path: '/gestione-abbonamenti/rispedizioni-libri-omaggio',
          name: 'rispedizioni-libri',
          component: () => import('./views/rispedizioni-libri.vue')
        },
        {
          path: '/etichette-editoria/',
          name: 'etichette-editoria',
          component: () => import('./views/editoria.vue')
        },
        {
          path: '/banner-pubblicitari/',
          name: 'banner-pubblicitari',
          component: () => import('./views/banners.vue')
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
