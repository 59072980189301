// axios
import axios from 'axios'

//const baseURL = 'http://localhost:8000'
const baseURL = 'https://amministrazione.futuranet.it'


export default axios.create({
  baseURL,
  headers: {
    common: {
      authorization: `Bearer ${window.getCookie('token')}`
    }
  }
})
