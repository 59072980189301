/* eslint-disable */
export default {
  pages: {
    key: "title",
    data: [
      {title: 'Dashboard',   url: '/',      icon: 'ActivityIcon', is_bookmarked: false},
      {title: 'File CGR', url: '/gestione-abbonamenti/cgr', icon: 'FileTextIcon', is_bookmarked: false},
      {title: 'Liste omaggio', url: '/gestione-abbonamenti/liste-omaggio', icon: 'FileTextIcon', is_bookmarked: false},
      {title: 'Rispedizioni riviste', url: '/gestione-abbonamenti/rispedizioni-riviste', icon: 'FileTextIcon', is_bookmarked: false},
    ]
  }
}
/* eslint-enable */
